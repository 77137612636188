.contentLoader {
  padding-right: 10px;
}
.content-wrapper {
  min-height: calc(100vh - 115px) !important;
}
.content-wrapper .main-content {
  display: flex;
  flex-direction: column;
  padding: 39px 69px;
  margin: 40px;
  background-color: #fff;
}
@media (max-width: 768px) {
  .content-wrapper .main-content {
    padding: 0;
    margin: 40px 0;
  }
}
.content-wrapper .main-content-box {
  border-radius: 7px;
  box-shadow: 0 0 17px 1.2px rgba(0, 0, 0, 0.05);
}
@media (max-width: 768px) {
  .content-wrapper .main-content-box {
    border-radius: 0;
    box-shadow: none;
  }
}
.content-wrapper-dark {
  min-height: calc(100vh - 115px) !important;
  background-color: #1a2133;
}
.content-wrapper-dark .main-content-dark {
  display: flex;
  flex-direction: column;
  padding: 39px 69px;
  margin: 40px;
  background-color: #272f45;
}
.content-wrapper-dark .main-content-dark b,
.content-wrapper-dark .main-content-dark div {
  color: white;
}
@media (max-width: 768px) {
  .content-wrapper-dark .main-content-dark {
    padding: 0;
    margin: 40px 0;
  }
}
.content-wrapper-dark .main-content-box-dark {
  border-radius: 7px;
  box-shadow: 0 0 17px 1.2px rgba(0, 0, 0, 0.05);
}
@media (max-width: 768px) {
  .content-wrapper-dark .main-content-box-dark {
    border-radius: 0;
    box-shadow: none;
  }
}
