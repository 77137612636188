.viewContent {
    width: 300px;
}

.dateContent {
    width: 300px;
}

.uploadText {
    width: 200px !important;
    overflow: hidden !important;
    line-height: 12px !important;
    text-align: right !important;
}

.requestLoader {
    margin: 5px -40px;
    position: absolute;
    background-color: #fff;
    opacity: .8;
    display: none;
}
.submitButton {
    margin-right: 25px;
}

#basic_password {
    position: absolute;
    top: 0;
}

#__lpform_innoTapForm_password_icon {
    position: absolute !important;
    top:0 !important;
    right: 10px !important;
    display: none;
}

.ant-input-password-icon{
    display:none;
}
.site-form-item-icon {
    opacity: .5;
}
#__lpform_innoTapForm_id_icon { 
    position: relative !important;
    right: 10px !important;
}

#__lpform_innoTapForm_adId_icon {
    position: relative !important;
    right: 10px !important;
}
input {
    background:none !important; 

}

.ant-input-affix-wrapper-lg {
    padding: 0 0;
    font-size: 16px;
}
.ant-input-affix-wrapper > input.ant-input {
    padding: 0 0 0 10px;
    border: none;
    outline: none;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
    top:-8px;
}

.uploadButton {
    height:40px;
    margin-left: 0;
    margin-right: 0;
    width: 40%;
    padding-left: 0;
    padding-right: 0;
}

.uploadText {
    border-width: 1px;
    border-style: 'solid';
    border-color: '#ccc';
    height:40px;
    line-height: 2.5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    padding: 12px 12px 0 0;
    width: 60%;
}

.uploadTextContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}