.hide {
  display: none !important;
}
#footer {
  font-family: "proxima-nova", sans-serif;
  background: #eaeaea;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12.5px;
  color: #808285;
  border-top: 2px solid #e1e0e0;
  bottom: 0;
}
#footer span {
  padding: 0 10px 0 10px;
}
#footer-dark {
  font-family: "proxima-nova", sans-serif;
  background: #2a374b;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12.5px;
  color: #808285;
  border-top: 2px solid #2a374b;
  bottom: 0;
}
#footer-dark span {
  padding: 0 10px 0 10px;
}
