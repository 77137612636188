.content-wrapper {
  min-height: calc(100vh - 115px) !important;

  .main-content {
    display: flex;
    flex-direction: column;
    padding: 39px 69px;
    margin: 40px;
    background-color: #fff;

    @media (max-width: 768px) {
      padding: 0;
      margin: 40px 0;
    }
  }

  .main-content-box {
    border-radius: 7px;
    box-shadow: 0 0 17px 1.2px rgba(0, 0, 0, .05);

    @media (max-width: 768px) {
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.content-wrapper-dark {
  min-height: calc(100vh - 115px) !important;
  background-color: rgb(26,33,51);

  .main-content-dark {
    display: flex;
    flex-direction: column;
    padding: 39px 69px;
    margin: 40px;
    background-color: rgb(39,47,69);

    b, div {
      color: white;
    }

    @media (max-width: 768px) {
      padding: 0;
      margin: 40px 0;
    }
  }

  .main-content-box-dark {
    border-radius: 7px;
    box-shadow: 0 0 17px 1.2px rgba(0, 0, 0, .05);

    @media (max-width: 768px) {
      border-radius: 0;
      box-shadow: none;
    }
  }
}